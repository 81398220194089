import CircularProgress from '@mui/material/CircularProgress';
import Styles from '../../lib/Styles';
import classes from './Loading.module.scss';

export default function Loading({text="Loading"}) {
    const cx: (arg0: string) => string = new Styles(classes).cx;
    return (
        <div className={cx('loading')}>
            <div className={cx('text')}>
            {text}&nbsp;&nbsp;
            </div>
            <div className={cx('spinner')}>
            <CircularProgress size={20} color={'primary'}/>
            </div>
            &nbsp;
        </div>
    );
}
