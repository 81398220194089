import Configuration from "./configuration";
import parseCookies from "./parse-cookies";
import jwt_decode from 'jwt-decode';

/**
 * This routine is used to get the accessToken's data.  To do this, it needs
 * both the accessToken, and the list of env vars via process.  In the calling getServerSideProps(ctx) method,
 * process can be obtained via:
 * 
 *  const clientProcess = getPublicEnvVars().process;
 *  const serverProcess = { env:
 *       {'CLIENT_ID' : process.env.CLIENT_ID,
 *        'CLIENT_SECRET' : process.env.CLIENT_SECRET}};
 *  const process = {env: {...clientProcess.env, ...serverProcess.env}};
 * 
 * @param {*} accessToken 
 * @param {*} process 
 * @returns 
 */
export const introspectAccessToken = async (accessToken: string, process: any) => {
    const url = Configuration.getIntrospectionURL(process);
    const clientId = process.env.CLIENT_ID;
    const clientSecret = process.env.CLIENT_SECRET;
    const params = {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: `token=${accessToken}&client_id=${clientId}&client_secret=${clientSecret}&token_type_hint=access_token`
    }
    const response = await fetch(url, params)
    const jsn = await response.json();
    return jsn;
}

export const fetchData = async (method: string, url = "", data: object = {}, token = "") => {
    const params: any = {
        method: method,
        cache: 'no-cache',
        redirect: 'follow',
        referrerPolicy: 'no-referrer',
        headers: {
            Accept: 'text/json',
            'Content-Type': 'text/json',
            Authorization: 'Bearer ' + token,
        },
    }

    // If using localhost then remove authorization param, similar to what we do in the data-hooks for accessing locally hosted APIs.
    if (!token || (url.indexOf('//localhost:') > 0)) {
        delete params.headers.Authorization;
    }

    if (method.toUpperCase() !== 'GET') {
        params.body = JSON.stringify(data)
    }

    const response = await fetch(url, params)
    return response
}

export const getData = async (url = "", token?: string) => {
    return fetchData('GET', url, {}, token)
}

export const postData = async (url = "", data: object = {}, token?: string): Promise<Response> => {
    return fetchData("POST", url, data, token);
}

export const decodeContactIdFromCookies = async (cookies: any) => {
    let contactId = '';
    if (cookies) {
        const sessionToken = cookies['__Secure-next-auth.session-token'];
        if (sessionToken) {
            try {
                const decodedSessionToken: any = jwt_decode(sessionToken);
                contactId = decodedSessionToken.contactid;
            } catch(error) {
                console.error('Problem encountered while checking contactid.');
            }
        }
    }
    return contactId;
}

export const decodeCookies = async (ctx: any, cookies: any, clientProcess: any, serverProcess: any) => {
    const combinedProcess = { env: { ...clientProcess.env, ...serverProcess.env } };
    const props: any = { deployment: clientProcess.env.NEXT_PUBLIC_DEPLOYMENT, authorized: false, accessTokenExpirationDate: 0, username: null }
    if (cookies) {
        const sessionToken = cookies['__Secure-next-auth.session-token']
        if (sessionToken) {
            try {
                const decodedSessionToken: any = jwt_decode(sessionToken);
                const accessToken = decodedSessionToken.accessToken
                const accessTokenData = await introspectAccessToken(accessToken, combinedProcess);
                if (accessTokenData.active) {
                props.username = (accessTokenData.username) ? accessTokenData.username : null; // This is the email which was used to authenticate with SalesForce.
                props.accessTokenExpirationDate = accessTokenData.exp * 1000;
                props.authorized = true;
                props.contactid = decodedSessionToken.contactid;
                props.accessToken = accessToken ? accessToken : null;
                    parseCookies([`authorized=${true}`], ctx);
                }
                props.process = clientProcess;
                return { props }
            } catch (error) {
                console.error('Problem encountered while checking authorization.');
                props.accessTokenExpirationDate = 0;
                const authorized = false;
                props.authorized = authorized;
                props.username = null;
                props.accessToken = null;
                parseCookies([`authorized=${authorized}`], ctx);
                props.process = clientProcess;
                return { props };
            }
        } else {
            props.accessTokenExpirationDate = 0;
            const authorized = false;
            props.authorized = authorized;
            props.username = null;
            props.accessToken = null;
            parseCookies([`authorized=${authorized}`], ctx);
            props.process = clientProcess;
            return { props };
        }
    }
    props.accessTokenExpirationDate = 0;
    props.authorized = null;
    props.username = null;
    props.process = clientProcess;
    return { props }
}