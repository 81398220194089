import LogoutIcon from '@mui/icons-material/Logout';
import { Tooltip } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { useCallback, useContext, useEffect, useState } from "react";
import { useRouter } from 'next/router';
import recordApplicationInsights, { IApplicationInsights } from '../../lib/api-utility';
import { configuration } from '../../lib/configuration';
import AppContext from '../../store/app-context';
import InstrumentedButton from '../InstrumentedButton';

import Styles from '../../lib/Styles';
import Loading from '../Loading/Loading';
import classes from './OneControl.module.scss';
import { logout } from '../../lib/utility';

const StyledAppBar: any = styled(AppBar)({
    color: 'white',
    backgroundColor: 'black',
    bar: {
        backgroundColor: 'black',
        color: 'red'
    }
});

export default function ApplicationBar() {
    const cx = new Styles(classes).cx;

    const { developerMode, page, setUsername, requestLogout, setRequestLogout, loggingOut, setLoggingOut, contactId, setContactId } = useContext(AppContext);
    const process = configuration.getProcess();

    const [dateLabel, setDateLabel] = useState<string>('');
    const [timeLabel, setTimeLabel] = useState<string>('');
    const [displayLogoutIcon, setDisplayLogoutIcon] = useState<boolean>(false);

    const router = useRouter();

    useEffect(() => {
        const path = router.pathname;
        const dispIcon = (path !== '/') && (path !== '/start') && (path !== '/login') && (path !== '/about');
        setDisplayLogoutIcon(dispIcon);
    }, [router])

    useEffect(() => {
        let intervalId: NodeJS.Timer | null = setInterval(() => {
            const newDate = new Date();
            const newDateLabel = newDate.toLocaleDateString('en-us', { month: 'short', weekday: 'short', day: 'numeric' });
            const newTimeLabel = newDate.toLocaleTimeString('en-us', { hour: 'numeric', minute: '2-digit' });
            if (newDateLabel !== dateLabel) {
                setDateLabel(newDateLabel);
            }
            if (newTimeLabel !== timeLabel) {
                setTimeLabel(newTimeLabel);
            }
        }, 1000)

        return () => {
            if (intervalId) {
                clearInterval(intervalId)
            }
            intervalId = null;
        };
    })

    const logoutHandler = useCallback(
        async () => {
            setLoggingOut(true);
            const domain = process.env.NEXT_PUBLIC_DOMAIN;
            const callbackUrl = `${domain}`;

            const data: IApplicationInsights = {
                contactId: contactId,
                component: 'AppBar',
                developerMode: developerMode ? 'true' : 'false',
                name: `${page}_Logging_Out`,
                page: page,
                properties: {
                }
            };
            recordApplicationInsights(data);

            setUsername('logging out');
            setContactId(null);
            localStorage.removeItem('kcode');

            setRequestLogout(false)
            logout(callbackUrl);
        }, [process.env.NEXT_PUBLIC_DOMAIN, page, developerMode, setLoggingOut, setRequestLogout, setUsername, contactId, setContactId])

    useEffect(() => {
        let handle: any = null;
        if (requestLogout) {
            setLoggingOut(true);
            handle = setTimeout(() => {
                logoutHandler();
            }, 0)
        }
        return () => {
            if (handle) {
                clearTimeout(handle);
                handle = null;
            }
        }
    }, [requestLogout, setRequestLogout, logoutHandler, setLoggingOut])

    return (
        <Box sx={{ flexGrow: 1 }}>
            <StyledAppBar position="static">
                <Toolbar>
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1 }} />
                    {displayLogoutIcon && (<>
                        {loggingOut ? (
                            <Loading text={'Logging out'} />
                        ) : (
                            <InstrumentedButton className={cx('instrumented-icon-button')} title='Log Out' onClick={logoutHandler} component="Navigation"><Tooltip title={'Logout'} arrow><LogoutIcon /></Tooltip></InstrumentedButton>
                        )}
                    </>
                    )}
                </Toolbar>
            </StyledAppBar>
        </Box>
    );
}
