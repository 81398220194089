import { MouseEventHandler, useContext } from 'react';
import recordApplicationInsights, { IApplicationInsights } from "../../lib/api-utility";
import AppContext from '../../store/app-context';

export interface IInstrumentedButton {
    title: string,
    component?: string,
    kcode?: string,
    index?: string | number,
    onClick?: MouseEventHandler,
    className?: string,
    href?: string,
    children?: JSX.Element,
    properties?: object
}

// Example usage:
//
// <InstrumentedButton className={cx('button-anchor')} href="tel:432-547-7378" title={'CALL'} />
//
// <InstrumentedButton  className={cx('logout')} title='Log Out' onClick={logoutHandler} component="Navigation" />
//
// <InstrumentedButton  className={cx('logout')} title='Log Out' onClick={logoutHandler} component="Navigation" ><em>Click me to log out</em></InstrumentedButton>

function InstrumentedButton(props: IInstrumentedButton) {
    const { title, component, index, onClick, className, href, properties = {} } = props;
    const { developerMode, page, contactId } = useContext(AppContext);

    return (
        <>
            {!href &&
                <button className={className ? className : ''} onClick={(e) => {
                    const kcode = localStorage.getItem('kcode');
                    const data: IApplicationInsights = {
                        kcode: kcode,
                        contactId: contactId,
                        component: component,
                        index: '' + index,
                        developerMode: developerMode ? 'true' : 'false',
                        name: (component ? `${component}` : `${page}`) + `_Selected_Button_${title}`,
                        page: page,
                        properties: properties
                    };
                    recordApplicationInsights(data);
                    if (onClick) {
                        onClick(e);
                    }
                }}>
                    {!props.children &&
                        title
                    }
                    {props.children &&
                        props.children
                    }
                </button>
            }
            {href &&
                <a href={href}>
                    <button className={className ? className : ''} onClick={(e) => {
                        const kcode = localStorage.getItem('kcode');
                        const data: IApplicationInsights = {
                            kcode: kcode,
                            contactId: contactId,
                            component: component,
                            index: '' + index,
                            developerMode: developerMode ? 'true' : 'false',
                            href: href ? href : 'undefined',
                            name: (component ? `${component}` : `${page}`) + `_Selected_Button_${title}`,
                            page: page,
                            properties: properties
                        };
                        recordApplicationInsights(data);
                        if (onClick) {
                            onClick(e);
                        }
                    }}>
                        {!props.children &&
                            title
                        }
                        {props.children &&
                            props.children
                        }
                    </button>
                </a>
            }
        </>
    );
}

export default InstrumentedButton;
