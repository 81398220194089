import { useEffect, useContext } from "react";
import { useRouter } from 'next/router';
import { hasTokenExpired } from "../lib/utility";
import { configuration } from '../lib/configuration';
import AppContext from '../store/app-context';

export { getServerSideEnvVars as getServerSideProps } from "../lib/server-props";

// This mechanism makes some properties from the server available to the wrapped page.
const withServerPropsCopiedToClient = (WrappedPage: any) => {
    return (props: any) => {
        const { authorized, accessToken, accessTokenExpirationDate, email, username, process, contactid } = props;
        const { setAuthenticated, setEmail, setUsername, setToken, setContactId, setRequestLogout } = useContext(AppContext);
        const router = useRouter();

        configuration.setProcessIfNotAlreadySet(process);

        useEffect(() => {
            const tokenIsExpired = hasTokenExpired(accessTokenExpirationDate);
            setToken(tokenIsExpired ? null : accessToken);
            setAuthenticated(authorized);
            setUsername(username);
            setEmail(email);
            setContactId(contactid);

            if (tokenIsExpired || (!authorized)) {
                localStorage.removeItem('kcode');
            }

            if (router.pathname === '/') {
                const query: any = router.query;
                const code: string = query && query['kcode'];
                if (code) {
                    localStorage.setItem('kcode', code);
                }
            }

        }, [accessTokenExpirationDate, accessToken, contactid, setToken, authorized, setAuthenticated, router.query, setContactId]);

        return (
            <WrappedPage {...props} />
        )
    }
}

export default withServerPropsCopiedToClient;